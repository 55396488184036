<template>
  <v-app id="inspire">
    <v-system-bar app>
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar>
    <v-app-bar app color="primary" dark>
      <v-container fluid class="py-0 fill-height">
        <div>
          <v-avatar>
            <img
              src="../../assets/images/profile.png"
              size="54"
              alt="John"
            />
          </v-avatar>
        </div>
        <v-btn key="Dashboard" text> Dashboard </v-btn>
        <v-btn key="messages" text> Messages </v-btn>
        <v-btn key="businesses" text> Businesses </v-btn>
        <v-spacer></v-spacer>
        <v-responsive max-width="260">
          <v-text-field
            dense
            flat
            hide-details
            rounded
            solo-inverted
          ></v-text-field>
        </v-responsive>
      </v-container>
    </v-app-bar>
    <v-main>
       <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: null,
    links: [
      ["mdi-inbox-arrow-down", "Inbox"],
      ["mdi-send", "Send"],
      ["mdi-delete", "Trash"],
      ["mdi-alert-octagon", "Spam"],
    ],
  }),
  mounted() {
    this.$vuetify.theme.themes.light.secondary = "#FEC735";
    this.$vuetify.theme.themes.dark.secondary = "#FEC735";
  },
};
</script>